import { useState } from 'react';
import {
  HomeOutlined,
  UserOutlined,
  CreditCardOutlined,
  PhoneOutlined,
  PayCircleOutlined
} from '@ant-design/icons';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Typography } from 'antd';


export default function Navigator() {
  const history = useHistory();
  const [current, setCurrent] = useState('home');

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: 60,
        padding: '0px 10px',
        borderTop: '1px solid #eee',
        paddingBottom: 10,
        background: '#fff',
      }}
    >
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: '#fff',
        }}
        onClick={() => {
          history.push('/');
          setCurrent('home');
        }}
      >
        <HomeOutlined
          style={{
            fontSize: 20,
            color: current == 'home' ? '#FAD400' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current == 'home' ? '#FAD400' : '#666',
          }}
        >
          Trang chủ
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 45,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          background: "#fff",
        }}
        onClick={() => {
          history.push("/vay");
          setCurrent("pay");
        }}
      >
        <PayCircleOutlined
          style={{
            fontSize: 20,
            color: current == "pay" ? "#FAD400" : "#666",
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current == "pay" ? "#FAD400" : "#666",
          }}
        >
          Số tiền vay
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 60,
          borderRadius: 25,
          minWidth: 60,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        onClick={() => {
          setCurrent('wallet');
          history.push('/wallet');
        }}
      >
        <CreditCardOutlined
          style={{
            fontSize: 20,
            color: current == 'wallet' ? '#FAD400' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current == 'wallet' ? '#FAD400' : '#666',
          }}
        >
          Tài khoản
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: '#fff',
        }}
        onClick={async () => {
          setCurrent('cskh');
          history.push('/cskh')
        }}
      >
        <PhoneOutlined
          style={{
            fontSize: 20,
            color: current == 'cskh' ? '#FAD400' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current == 'cskh' ? '#FAD400' : '#666',
          }}
        >
          CSKH
        </Typography.Text>
      </motion.div>
      <motion.div
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.9 }}
        style={{
          minHeight: 45,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: '#fff',
        }}
        onClick={() => {
          history.push('/me');
          setCurrent('me');
        }}
      >
        <UserOutlined
          style={{
            fontSize: 20,
            color: current == 'me' ? '#FAD400' : '#666',
          }}
        />
        <Typography.Text
          style={{
            fontSize: 12,
            color: current == 'me' ? '#FAD400' : '#666',
          }}
        >
          Hồ sơ
        </Typography.Text>
      </motion.div>
    </div>
  );
}
