import moment from "moment"
import logo_contract from "../assets/logo_contract.png"
import signature from "../assets/signature.jpg"
import { currencyFormatVND } from "../utils/CurrencyFormat"
import { useEffect, useState } from "react"
import api from '../api';

function ContractFormNew(props) {
    const { contract = {}, profile = {}, currentContract } = props
    const [systemConfig, setSystemConfig] = useState(undefined)
    async function getSystemConfig() {
        const { data } = await api.get("/systemConfiguration/getSystemConfig");
        setSystemConfig(data.data)
    }

    const [benA, setBenA] = useState({
        title: "BÊN A ( BÊN CHO VAY ) : NGÂN HÀNG KB KOOKMIN - VIỆT NAM (KB KOOKMIN BANK - VIET NAM)",
        des: [
            {
                title: "Người đại diện",
                value: "Kwon Tae Doo"
            }, {
                title: "Chức vụ",
                value: "Giám Đốc Đại Diện Pháp Lý"
            }, {
                title: "Mã số thuế",
                value: "0108572450"
            }, {
                title: "Ngày hoạt động",
                value: "2019-01-07"
            }, {
                title: "Quản lý bởi",
                value: "Cục Thuế Thành phố Hà Nội"
            }, {
                title: "Loại hình DN",
                value: "Công ty trách nhiệm hữu hạn ngoài NN"
            }, {
                title: "Tình trạng",
                value: "Đang hoạt động (đã được cấp GCN ĐKT)"
            }, {
                title: "Sau đây gọi tắt là",
                value: "Bên A"
            }
        ]
    })

    const sugession = {
        title: "Bên Vay đề nghị và Bên Cho Vay (KB Kookmin Bank) đồng ý cấp cho Bên Vay một Khoản Vay như sau:",
        des: [
            "Loại hình vay. Vay tín dụng thanh toán trả góp mỗi tháng gốc lẫn lãi.",
            "Phương thức cho vay: Giải ngân trực tiếp thông qua ứng dụng thanh toán online.",
            "Hình thức cho vay: cho vay trả góp không thế chấp tài sản.",
            "Phương thức giải ngân: chuyển khoản vào tài khoản của Bên Vay Cung cấp trên ứng dụng dịch vụ thanh toán online.",
            "Thời hạn cho vay: tính từ ngày tiếp theo của Ngày Giải Ngân và kết thúc tại thời điểm Bên Vay trả hết nợ gốc và lãi tiền vay theo Lịch Trả Nợ đính kèm hoặc tại thời điểm Bên Vay hoàn tất toàn bộ nghĩa vụ thanh toán Khoản Vay, tùy thuộc điều kiện nào đến trước."
        ]
    }

    const articles1 = {
        title: "KB Kookmin Bank sẽ giải ngân Khoản Vay cho Bên Vay chỉ khi tất cả các điều kiện sau được thỏa mãn:",
        des: [
            "Hợp đồng này quy định các điều khoản chung điều chỉnh tất cả các dịch vụ mà Bên B cung cấp cho Bên A.",
            "Khi cung cấp một dịch vụ cụ thể, Bên B và Bên A sẽ ký kết để nêu rõ nội dung dịch vụ và các điều khoản cần thiết khác.",
            "Các điều khoản nêu trên là một phần không tách rời của hợp đồng này. Nếu có sự khác biệt giữa hợp đồng này và các điều khoản của hợp đồng này sẽ được áp dụng.",
            "Hợp đồng này có thời hạn kể từ ngày ký và kết thúc tại thời điểm Ngân Hàng chấm dứt sử dụng. Tất cả dịch vụ của Bên B tại các điều khoản đã ký."
        ]
    }
    const articles2 = [
        "Nếu số tiền thực nhận được ít hơn số tiền vay đã thỏa thuận trong hợp đồng này, một trong hai bên sẽ thông báo cho bên kia qua nền tảng trong 3 ngày và bên A sẽ thu xếp để số dư được cộng vào thẻ ngân hàng của bên B trong vòng một ngày làm việc, và thời gian cho vay sẽ tự động bắt đầu tính.",
        "Nếu số tiền nhận lớn hơn số tiền vay đã thỏa thuận trong hợp đồng này, một trong hai bên sẽ thông báo cho bên kia qua nền tảng hoặc điện thoại trong vòng ba ngày và bên B sẽ thu xếp hoàn trả số tiền vượt quá trong vòng một ngày làm việc. Nếu không số tiền ban đầu của khoản vay trong hợp đồng này sẽ được tính theo số tiền thanh toán thực tế.",
        "Sau khi người dùng qua vòng xét duyệt khoản vay, bộ phận giải ngân sẽ thẩm định. Nếu khách hàng đủ điều kiện xét duyệt thì bộ phận giải ngân sẽ cung cấp mật khẩu rút tiền và hỗ trợ khách hàng giải ngân.",
        "Bên A không sử dụng bất kỳ dịch vụ nào vào mục đích vi phạm pháp luật Việt Nam.",
        "Các Bên có nghĩa vụ tuân thủ các quy định của pháp luật Việt Nam về bảo vệ thông tin thu thập được từ Hợp đồng này.",
        "Nếu bên B không có tài sản đảm bảo như ô tô nhà đất do bên B đứng tên chính chủ thì bên B cần cọc 5 - 10% khoản vay để xác minh tài chính và khả nặng chỉ trả của bên B. Khoản cọc sẽ được trừ vào những tháng cuối của hợp đồng vay."
    ]
    const articles3a = [
        "Bảo đảm bảo mật thông tin của Bên B, chỉ được chuyển giao thông tin cho bên thứ ba khi có sự đồng ý của Bên B trừ các trường hợp theo quy định Pháp luật.",
        "Giải quyết khiếu nại của Bên B trong vòng 05 (năm) ngày làm việc kể từ ngày nhận được khiếu nại.",
        "Bồi thường thiệt hại trực tiếp do lỗi của mình gây ra cho Bên B.",
        "Thông báo cho Bên B về sự thay đổi các thông tin đã được Bên B cung cấp bằng văn bản trong ngày sau khi thay đổi hoặc nhận được yêu cầu tử Bên B. Hợp tác với Bên B để hoàn tất các thủ tục liên quan đến việc thay đổi thông tin nêu trên.",
    ]
    const articles3b = [
        "Chịu hoàn toàn trách nhiệm trước Pháp luật khi không thực hiện đúng cam kết theo Hợp đồng đã ký theo Bộ luật dân sự 2015 (BLDS 2015) (Luật số 91/2015/QH13 ngày 24/11/2015)",
        "Bên B vay vốn tổ chức tín dụng phải bảo đảm sử dụng vốn vay đúng mục đích, hoàn trả nợ gốc và lãi.",
        "Bồi thưởng thiệt hại trực tiếp do lỗi của mình gây ra cho Bên B.",
        "Thanh toán đầy đủ, đúng thời hạn các khoản cước theo quy định của hợp đồng này, kể cả trong thời gian chờ giải quyết khiếu nại và chịu các chi phí (như phí chuyển tiền, phí ngân hàng và các chi phí khác có liên quan, nếu có) phát sinh khi thanh toán cước.",
        "Không được từ chối giao kết hợp đồng hoặc đơn phương chấm dứt hợp đồng với Bên A trừ các trường hợp được quy định tại Điều 26 quỹ người vay.",
        "Khi người vay trong quá trình xét duyệt khoản vay không thành công do nhiều yếu tố khác nhau như chứng minh thư sai, thẻ ngân hàng sai, danh bạ sai. Việc thông tin sai lệch này sẽ khiến hệ thống phát hiện nghi ngờ gian lận hoặc giả mạo hồ sơ vay và bên vay phải có trách nhiệm chủ động hợp tác với bên A để xử lý theo quy định."
    ]
    const articles4 = {
        des: "Tất cả các bên trong hợp đồng sẽ thực hiện nghiêm túc các nghĩa vụ theo hợp đồng của mình và không bên nào được chấm dứt thỏa thuận này trừ khi các bên đạt được thỏa thuận thông qua tham vấn hoặc theo thỏa thuận này. Nếu bất kỳ bên nào vi phạm hợp đồng, bên vi phạm sẽ chịu các chi phí và tổn thất mà các bên không phải chịu do hành vi vi phạm, bao gồm nhưng không giới hạn ở phí điều tra, phí kiện tụng, phí luật sư, vv do bên vi phạm chịu.Nếu bên vỡ nợ là bên B thì bên A có quyền chấm dứt hợp đồng này ngay lập tức và yêu cầu bên B hoàn trả dư nợ gốc, lãi và các chi phí khác.Tại thời điểm này, bên B cung cấp thanh toán cho bên A tất cả các khoản phí, quản lý tài khoản, phí xét duyệt nhanh, phí quản lý tài khoản(nếu có) trong một khoảng thời gian, phí quản lý quá hạn ( nếu có ) và các khoản phí khác do khách hàng mất khả năng chi trả.",
        note: "Bên B thanh toán theo trình tự sau",
        content: [
            "Tất cả các khoản phí khác phát sinh theo thỏa thuận này.",
            "Phí quản lý tài khoản quá hạn theo quy định trên hợp đồng.",
            "Lãi quá hạn theo quy định.",
            "Phí quản lý tài khoản vay quy định.",
            "Phí duyệt hồ sơ nhanh.",
            "Phí quản lý tài khoản thông thường",
            "Phí tất toán hợp đồng 5%",
            "Phí đền bù hợp đồng 40% / tổng khoản vay",
        ],
        commit: "Tôi xin xác nhận những thông tin trên đây cùng với các thông tin và tài liệu khác được tôi cung cấp cho KB KOOKMIN BANK trong quá trình đề nghị vay vốn là chính xác, trung thực, và tôi cũng đồng ý cho NGÂN HÀNG KB KOOKMIN - VIỆT NAM (KB KOOKMIN BANK - VIET NAM) được thu thập, sử dụng cũng như chia sẻ cho bên thứ ba các thông tin liên quan đến tôi và thông tin cá nhân được ghi nhận tại bất kỳ tài liệu nào được ký bởi tôi (có hiệu lực tại từng thời điểm), thông tin trên CMND/Thẻ CCCD sẽ là thông tin cuối cùng và các tài liệu liên quan sẽ vẫn có giá trị pháp lý.",
        agree: "Tôi đồng ý ký kết Hợp Đồng này thông qua phương tiện điện tử. Trong mọi trường hợp"
    }

    useEffect(() => {
        getSystemConfig()
    }, [])

    useEffect(() => {
        if (systemConfig?.supportUrl) {
            const check = benA.des.find(item => item.title === "Hotline")
            if (check) {
                benA.des.find(item => item.title === "Hotline").value = systemConfig?.supportUrl
                setBenA({ ...benA })
            } else {
                benA.des.splice(4, 0, {
                    title: "Hotline",
                    value: systemConfig?.supportUrl
                });
                setBenA({ ...benA })
            }
        }
    }, [systemConfig])

    return (
        <div style={{ fontSize: "12px" }}>
            <div style={{ textAlign: "center" }}>
                <img src={logo_contract} alt="" width={"70%"} height={"auto"} />
            </div>
            <h2 style={{ textAlign: "center", fontWeight: "800", marginTop: "20px" }}>HỢP ĐỒNG VỐN VAY TÍN DỤNG</h2>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>THÔNG TIN NGƯỜI ĐỀ NGHỊ</h4>
            <table style={{ width: "100%", borderCollapse: "collapse", fontSize: "11px" }}>
                <tr>
                    <td style={{ border: "1px solid", width: "45%", fontWeight: "600" }}>Họ tên bên vay:</td>
                    <td style={{ border: "1px solid", width: "55%" }}><span style={{ padding: "0 4px" }}>{profile?.kyc?.name}</span></td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid", width: "45%", fontWeight: "600" }} >Số Điện Thoại Đăng Ký</td>
                    <td style={{ border: "1px solid", width: "55%" }}><span style={{ padding: "0 4px" }}>{profile?.phone}</span></td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid", width: "45%", fontWeight: "600" }} >Số CMND/CCCD</td>
                    <td style={{ border: "1px solid", width: "55%" }}><span style={{ padding: "0 4px" }}>{profile?.kyc?.id_number}</span></td>
                </tr>
                <tr>
                    <td style={{ border: "1px solid", width: "45%", fontWeight: "600" }} >Số Tiền Đề Nghị Vay</td>
                    <td style={{ border: "1px solid", width: "55%" }}><span style={{ padding: "0 4px" }}>{contract?.amount || currentContract?.amount ? currencyFormatVND(contract?.amount || currentContract?.amount) + " VNĐ" : ""}</span></td>
                </tr>
            </table>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>
                {benA.title}
            </h4>
            {
                benA.des.map(item =>
                    <p style={{ display: "flex", marginBottom: "0px", gap: "4px" }}>
                        <span style={{ minWidth: "100px" }}>{item.title}:</span>
                        <span>{item.value}</span>
                    </p>)
            }

            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>
                BÊN B ( BÊN VAY):
            </h4>
            <p style={{ display: "flex", marginBottom: "0px", gap: "4px", fontWeight: "600" }}>
                <span style={{ minWidth: "100px" }}>Số hợp đồng:</span>
                <span>KIS-168-04HDTD</span>
            </p>
            <p style={{ display: "flex", marginBottom: "0px", gap: "4px", fontWeight: "600" }}>
                <span style={{ minWidth: "100px" }}>Ngày:</span>
                <span >{currentContract?.times ? moment().format("DD/MM/YYYY") : contract?.created_at ? moment(contract?.created_at).format("DD/MM/YYYY") : ""}</span>
            </p>
            <p style={{ display: "flex", marginBottom: "0px", gap: "4px", fontWeight: "600" }}>
                <span style={{ minWidth: "100px" }}>Kỳ hạn vay:</span>
                <span >{currentContract?.times ? currentContract?.times + " tháng" : contract?.times ? contract?.times + " tháng" : ""}</span>
            </p>

            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>{sugession.title}</h4>
            <div>
                {
                    sugession.des.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        {index + 1} {item}
                    </p>)
                }
            </div>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>ĐIỀU 1: PHƯƠNG THỨC PHÁT HÀNH KHOẢN VAY</h4>
            <h4 style={{ fontWeight: "600" }}>{articles1.title}</h4>
            <div>
                {
                    articles1.des.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        1.{index + 1} {item}
                    </p>)
                }
            </div>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>ĐIỀU 2 : DỊCH VỤ CUNG CẤP</h4>
            <div>
                {
                    articles2.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        2.{index + 1} {item}
                    </p>)
                }
            </div>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>ĐIỀU 3: QUYỀN HẠN VÀ TRÁCH NHIỆM CỦA CÁC BÊN</h4>
            <h4 style={{ fontWeight: "600" }}> A. ĐỐI VỚI BÊN A</h4>
            <div>
                {
                    articles3a.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        3.{index + 1} {item}
                    </p>)
                }
            </div>
            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>B. ĐỐI VỚI BÊN B</h4>
            <div>
                {
                    articles3b.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        3.{index + 1 + articles3a.length} {item}
                    </p>)
                }
            </div>

            <h4 style={{ fontWeight: "600", marginTop: "12px" }}>ĐIỀU 4 : TRÁCH NHIỆM DO VI PHẠM HỢP ĐỒNG</h4>
            <p style={{ textAlign: "justify" }}>{articles4.des}</p>
            <p>{articles4.note}:</p>
            <div>
                {
                    articles4.content.map((item, index) => <p style={{ display: "flex", marginBottom: "0px", gap: "4px", textAlign: "justify" }}>
                        {index + 1}{")"} {item}
                    </p>)
                }
            </div>
            <p style={{ fontWeight: "600", marginTop: "12px", textAlign: "justify" }}>{articles4.commit}</p>
            <p style={{ fontWeight: "600", marginTop: "12px", textAlign: "justify" }}>{articles4.agree}</p>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div style={{ width: "45%", textAlign: "center", fontWeight: "600", fontSize: "10px" }}>
                    <p>NGƯỜI VAY</p>
                    {
                        contract?.signature_capture
                        && <>
                            <img src={contract?.signature_capture} alt="" width={"100%"} />
                            <p>{profile?.kyc?.name}</p>
                        </>
                    }
                </div>
                <div style={{ width: "55%", textAlign: "center" }}>
                    <img src={signature} alt="" width={"100%"} />
                </div>
            </div>

        </div >
    );
}

export default ContractFormNew;