function isEmptyString(stringValue) {
    if (!stringValue) {
        return true
    }
    if (stringValue === null) {
        return true
    }
    if (stringValue.length === 0) {
        return true;
    }
    if (stringValue === "") {
        return true
    }
    if (stringValue.trim() === "") {
        return true
    }
    return false
  }
  function isNotEmptyString(stringValue) {
    return !isEmptyString(stringValue)
  }
  module.exports = {
    isNotEmptyString,
    isEmptyString
  }
  