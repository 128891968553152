/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import "./Login.scss";
import { Image, Typography, Input, Button, message, Form } from "antd";
import { useHistory } from "react-router-dom";
import isCorrectNumberPhone from "../../utils/isCorrectNumberPhone";
import isVietnamese10PhoneNumber from "../../utils/isVietnamese10PhoneNumber";
import api from "../../api";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "../../redux/constants";
import { motion } from "framer-motion";
import logo from "../../assets/logo-khong-nen.png";
import _buildings from "../../assets/buildings.jpg";
import DieuKhoan from "../../components/DieuKhoan";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { PhoneOutlined, LockOutlined } from "@ant-design/icons";
import "./Login.scss";

export default () => {
  const router = useHistory();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    phone: "",
    password: "",
    repassword: "",
    check: false,
  });
  // const toggleCheck = (e) => setState({ check: e.target.checked });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  async function onFinish() {
    try {
      if (!state.phone || !state.password) {
        message.error("Không được để trống thông tin.");
        return;
      }
      if (
        !isCorrectNumberPhone(state.phone) &&
        !isVietnamese10PhoneNumber(state.phone)
      ) {
        message.error("Số điện thoại không đúng định dạng.");
        return;
      }
      if (state.repassword !== state.password) {
        message.error(
          "Mật khẩu không trùng nhau. Hãy xác nhận lại mật khẩu của bạn"
        );
        return;
      }
      if (state.password.length < 6 || state.password.length > 20) {
        message.error("Độ dài mật khẩu phải từ 6 - 20 ký tự.");
        return;
      }
      // if (!state.check) {
      //   message.error('Bạn phải đồng ý với điều khoản và dịch vụ để đăng ký');
      //   return;
      // }

      setLoading(true);
      const { data } = await api.post("/auth/signup", {
        phone: state.phone,
        password: state.password,
        repassword: state.password,
        check: true,
      });

      if (data.code === 405) {
        message.error(data.message);
        history.push("/auth?phone=" + state.phone);
        return;
      }

      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("role", data.role);
      dispatch({ type: LOGIN_SUCCESS, payload: {} });
      message.success("Tạo tài khoản thành công.");
      history.push("/")
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  const handleClick = () => {
    history.push("/auth");
  };
  return (
    <div
      className="login-container"
      style={{
        // backgroundImage: ` linear-gradient(to bottom, rgba(255,255,255,0.7) 0%,rgba(255,255,255,1) 100%), url('${_buildings}')`,
        minHeight: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="header-container header_signup">
        <LeftOutlined className="arrow-icon" onClick={() => router.goBack()} />{" "}
        <Typography.Text className="header-title">Đăng ký</Typography.Text>
        <div></div>
      </div>

      {/* <div className="form">
        <div className="form-header">
          <Typography.Text className="title">Đăng ký tài khoản</Typography.Text>
        </div>
        <div className="form-body">
          <Input
            className="input"
            autoComplete="off"
            value={state.phone}
            onChange={(e) => setState({ ...state, phone: e.target.value })}
            size="large"
            placeholder="Nhập số điện thoại"
          />

          <Input
            className="input"
            autoComplete="off"
            value={state.password}
            type="password"
            onChange={(e) => setState({ ...state, password: e.target.value })}
            size="large"
            placeholder="Mật khẩu"
          />

          <Input
            className="input"
            autoComplete="off"
            size="large"
            type="password"
            placeholder="Nhập lại mật khẩu"
            value={state.repassword}
            onChange={(e) => setState({ ...state, repassword: e.target.value })}
          />

          <div className="check-box">
            <Checkbox
              defaultChecked={false}
              onChange={(e) => {
                setState({ ...state, check: e.target.checked });
              }}
            >
              Đồng ý với
            </Checkbox>{' '}
            <DieuKhoan />
          </div>

          <Button
            className="login-btn"
            onClick={confirmSignup}
            loading={loading}
            size="large"
          >
            <Typography.Text style={{ color: '#fff' }} strong>
              {' '}
              Đăng ký
            </Typography.Text>
          </Button>
          <div style={{}}>
            <br />
            <Typography.Text
              style={{
                padding: '3px 0px',
                color: '#777',
                fontWeight: '400',
                paddingHorizontal: 20,
              }}
            >
              Độ dài mật khẩu từ 6 - 20 ký tự
            </Typography.Text>
            <br />
            <Typography.Text
              style={{
                padding: '3px 0px',
                color: '#777',
                fontWeight: '500',
              }}
            >
              Ví dụ :
            </Typography.Text>
            <br />
            <Typography.Text
              style={{
                padding: '3px 0px',
                color: '#777',
                fontWeight: '400',
                paddingHorizontal: 20,
              }}
            >
              Mật khẩu : 123456
            </Typography.Text>
          </div>

          <div className="form-footer">
            <Typography.Link
              onClick={() => history.push('/auth')}
              style={{ fontSize: 20, color: '#333', fontWeight:'bold'}}
            >
              Đã có tài khoản ? Đăng nhập ngay
            </Typography.Link>
          </div>
        </div>
      </div> */}

      <Form
        name="login"
        autoComplete="off"
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onFinish(values);
        }}
        className="style_login mt-2"
      >
        <Form.Item
          name="phone"
          label="Số điện thoại đăng nhập"
          rules={[
            {
              required: true,
              message: "Hãy nhập số điện thoại",
            },
          ]}
        >
          <div className="login__input__icon">
            <PhoneOutlined className="iconLeftInput" />
            <Input
              className="login__input"
              placeholder="Đăng nhập bằng số điện thoại"
              type="text"
              size="large"
              onChange={(e) => setState({ ...state, phone: e.target.value })}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[
            {
              required: true,
              message: "Hãy nhập mật khẩu ",
            },
            {
              max: 20,
              min: 6,
              message: "Mật khẩu từ 6 - 20 kí tự"
            }
          ]}
        >
          <div className="login__input__icon">
            <LockOutlined className="iconLeftInput" />
            <Input
              className="login__input"
              type={showPassword ? "password" : "text"}
              placeholder={"Nhập mật khẩu"}
              size="large"
              onChange={(e) => setState({ ...state, password: e.target.value })}
            />
            <div onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <EyeInvisibleOutlined className="eyeIcon" />
              ) : (
                <EyeOutlined className="eyeIcon" />
              )}
            </div>
          </div>
        </Form.Item>
        <Form.Item
          name="repassword"
          label="Nhập lại mật khẩu"
          rules={[
            {
              required: true,
              message: "Hãy nhập mật khẩu ",
            },
            {
              max: 20,
              min: 6,
              message: "Mật khẩu từ 6 - 20 kí tự"
            }
          ]}
        >
          <div className="login__input__icon">
            <LockOutlined className="iconLeftInput" />
            <Input
              className="login__input"
              type={showPassword ? "password" : "text"}
              placeholder={"Nhập lại mật khẩu"}
              size="large"
              onChange={(e) =>
                setState({ ...state, repassword: e.target.value })
              }
            />
            <div onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <EyeInvisibleOutlined className="eyeIcon" />
              ) : (
                <EyeOutlined className="eyeIcon" />
              )}
            </div>
          </div>
        </Form.Item>
        <div className="extra" onClick={() => handleClick()}>
          Bạn đã có tài khoản ?
        </div>
        <div className="style_buton">
          <Button
            htmlType="submit"
            className="login-btn"
            loading={loading}
            size="large"
          >
            <Typography.Link style={{ color: "#fff" }}>Đăng ký tài khoản</Typography.Link>
          </Button>
        </div>
      </Form>
    </div>
  );
};
